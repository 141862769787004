<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" lg="2">
        <v-select hide-details variant="underlined" label="Season" :disabled="isLoading" :loading="isLoading" :items="SEASONS" v-model="season" />
      </v-col>
      <v-col cols="12" sm="4" lg="5">
        <v-select hide-details variant="underlined" label="Division" :disabled="isLoading" :items="divisions" v-model="division" />
      </v-col>
      <v-col cols="12" sm="4" lg="5">
        <v-select hide-details variant="underlined" label="Team" :disabled="isLoading" :items="teamItems" v-model="team" />
      </v-col>
    </v-row>

    <template v-if="!team || (team == '-')">
      <div class="mt-6 text-overline">Standings</div>
      <v-data-table
        :headers="TABLE_HEADERS_STANDINGS"
        :items="standings"
        :items-per-page="-1"
      >
        <template v-slot:item.place="{ index, item }">
          {{index + 1}}
        </template>
        <template v-slot:bottom></template>
      </v-data-table>
    </template>

    <div class="mt-6 d-flex align-center">
      <div class="text-overline mr-3">Schedule</div>
      <v-spacer />
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" text :loading="isDownloading" :disabled="!team || (team == '-')">
            <v-icon :icon="mdiDownload" />
            <span class="ml-2 mr-1">Download</span>
            <v-icon :icon="mdiMenuDown" />
          </v-btn>
        </template>
        <v-list>
          <v-list-subheader>Download format</v-list-subheader>
          <v-list-item @click="downloadSpreadsheet">
            <template v-slot:prepend>
              <v-icon class="mr-3" color="rgb(83,148,103)" :icon="mdiTableLarge" />
            </template>
            <v-list-item-title>Spreadsheet (.csv)</v-list-item-title>
          </v-list-item>
          <v-list-item @click="downloadTeamCowboy">
            <template v-slot:prepend>
              <v-img class="mr-3" width="24" :src="TEAM_COWBOY_LOGO" />
            </template>
            <v-list-item-title>TeamCowboy (.txt)</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-data-table
      :headers="TABLE_HEADERS_EVENTS"
      :items="items"
      :items-per-page="-1"
    >
      <template v-slot:item.home="{ item }">
        <div class="d-flex align-center">
          <v-card class="mr-1 d-flex justify-center" style="opacity: .8;" flat border min-width="12" :color="(item.value.home.color || '').toLowerCase()">{{item.value.home.color ? '&nbsp;' : '?'}}</v-card>
          <div>{{item.value.home.name}}</div>
        </div>
      </template>
      <template v-slot:item.away="{ item }">
        <div class="d-flex align-center">
          <v-card class="mr-1 d-flex justify-center" style="opacity: .8;" flat border min-width="12" :color="(item.value.away.color || '').toLowerCase()">{{item.value.away.color ? '&nbsp;' : '?'}}</v-card>
          <div>{{item.value.away.name}}</div>
        </div>
      </template>
      <template v-slot:item.location="{ item }">
        <a target="_blank" :href="item.value.location.url">
          <v-icon class="mr-1" size="x-small" :icon="mdiOpenInNew" />{{item.value.location.name}}
        </a>
        <div v-if="item.value.notes" class="ml-5 text-caption">
          {{item.value.notes}}
        </div>
      </template>
      <template v-slot:bottom></template>
    </v-data-table>
  </div>
</template>

<script>
import OPServiceClient from '@/utils/op-service-client';
import { format } from '@/utils/time.js';
import { mdiDownload, mdiMenuDown, mdiOpenInNew, mdiTableLarge } from '@mdi/js';
import TEAM_COWBOY_LOGO from '@/assets/team-cowboy-logo.png';

const CLIENT = OPServiceClient(process.env.OP_DEV_CONFIG['service-url']);

const ROOT_URL = process.env.OP_DEV_CONFIG['service-url'].replace(/\/$/, '');

const DAY_ORDER = [ 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun' ];

function getDivision(team) {
  return team.day + ' ' + team.gender + ' D-' + team.division;
}

const SEASONS = [
  { title: 'Winter 2025', value: 'winter-2025' },
  { title: 'Fall 2024', value: 'fall-2024' },
  { title: 'Summer 2024', value: 'summer-2024' },
  { title: 'Spring 2024', value: 'spring-2024' },
  { title: 'Winter 2024', value: 'winter-2024' },
  { title: 'Fall 2023', value: 'fall-2023' },
  { title: 'Summer 2023', value: 'summer-2023' }
];

const TABLE_HEADERS_STANDINGS = [
  { title: 'Place', key: 'place', sortable: false },
  { title: 'Team', key: 'team', sortable: false },
  { title: 'Wins', key: 'w', sortable: false },
  { title: 'Losses', key: 'l', sortable: false },
  { title: 'Ties', key: 't', sortable: false },
  { title: 'GF', key: 'gf', sortable: false },
  { title: 'GA', key: 'ga', sortable: false },
  { title: 'Points', key: 'pts', sortable: false }
];

const TABLE_HEADERS_EVENTS = [
  { title: 'Date', key: 'date', sortable: false },
  { title: 'Time', key: 'time', sortable: false },
  { title: 'Home', key: 'home', sortable: false },
  { title: 'Away', key: 'away', sortable: false },
  { title: 'Score', key: 'score', sortable: false },
  { title: 'Location', key: 'location', sortable: false }
];

function formatDate(v, fmt) {
  return new Date(v + 'T00:00:00').toLocaleDateString(undefined, fmt);
}

function formatTime(v, fmt) {
  return new Date('0000-01-01T' + v).toLocaleTimeString(undefined, fmt);
}

function buildMapURL(v) {
  return 'https://maps.google.com/?q=' + encodeURIComponent(v);
}

const quoteCSVTerm = s => s.match(/,|"/) ? '"' + s.replace(/"/g, '""') + '"' : s;

const quoteTabDelimitedTerm = s => s.replace(/\t/g, '  ')

function download(data, fileName) {
  const link = document.createElement('a');
  link.href = encodeURI(data);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export default {
  name: 'App',

  data() {
    return {
      allEvents: [],
      allTeams: [],
      division: null,
      team: null,
      season: SEASONS[0].value,
      isLoading: false,
      isDownloading: false
    };
  },

  computed: {
    divisions() {
      const divisions = new Set();
      for (const team of this.allTeams)
        divisions.add(getDivision(team));
      return Array.from(divisions).sort((a, b) => (DAY_ORDER.indexOf(a.slice(0, 3).toLowerCase()) + a).localeCompare(DAY_ORDER.indexOf(b.slice(0, 3).toLowerCase()) + b));
    },

    teamsInDivisionByName() {
      const output = {};
      for (const team of this.allTeams.filter(x => getDivision(x) == this.division))
        output[team.name] = team;
      return output;
    },

    teamItems() {
      if (!this.division)
        return [];
      return [ { title: 'All', value: '-' }, ...Object.values(this.teamsInDivisionByName).map(x => x.name).sort() ];
    },

    standings() {
      const byTeam = {};
      for (const item of this.items) {
        const [ home, away ] = (item.score || '0-0').split('-').map(x => parseInt(x));
        const groups = [ [ item.home.name, home, away ], [ item.away.name, away, home ] ];
        for (const [ team, mine, theirs ] of groups) {
          if (!this.teamsInDivisionByName[team])
            continue;
          if (!byTeam[team])
            byTeam[team] = { team, pts: 0, w: 0, l: 0, t: 0, gf: 0, ga: 0 };
          if (!item.score)
            continue;
          byTeam[team].pts += mine > theirs ? 3 : mine == theirs ? 1 : 0;
          byTeam[team].w += mine > theirs ? 1 : 0;
          byTeam[team].l += mine < theirs ? 1 : 0;
          byTeam[team].t += mine == theirs ? 1 : 0;
          byTeam[team].gf += mine;
          byTeam[team].ga += theirs;
        }
      }
      return Object.values(byTeam).sort((a, b) => (b.pts - a.pts) || ((b.gf - b.ga) - (a.gf - a.ga)) || (b.gf - a.gf) || a.team.localeCompare(b.team));
    },

    items() {
      const selected = new Set((this.team == '-') ? this.teamItems.slice(1) : [ this.team ]);
      return this.allEvents.filter(x => selected.has(x.home_team_name) || selected.has(x.away_team_name)).map(x => ({
        date: formatDate(x.start_date, { weekday: 'long', month: 'numeric', day: 'numeric' }),
        time: formatTime(x.start_time, { hour: 'numeric', minute: '2-digit' }),
        home: {
          name: x.home_team_name,
          color: (x.home_color == x.away_color) && (this.teamsInDivisionByName[x.home_team_name] || {}).color_alt || x.home_color
        },
        away: {
          name: x.away_team_name,
          color: x.away_color
        },
        location: {
          name: x.location,
          url: buildMapURL(x.location)
        },
        score: x.score,

        dateRaw: x.start_date,
        timeRaw: x.start_time,
        notes: x.notes
      }));
    }
  },

  created() {
    Object.assign(this, { TABLE_HEADERS_EVENTS, TABLE_HEADERS_STANDINGS, TEAM_COWBOY_LOGO, SEASONS, mdiDownload, mdiMenuDown, mdiOpenInNew, mdiTableLarge });
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.isLoading = true;
      try {
        const output = await CLIENT.call('get-aggregate', { season: this.season });
        this.allTeams = output.teams;
        this.allEvents = output.events;
      } finally {
        this.isLoading = false;
      }
    },

    async loadSchedule() {
      this.isDownloading = true;
      try {
        const key = this.teamsInDivisionByName[this.team].schedule_key;
        return await CLIENT.call('get-schedule', { season: this.season, key });
      } finally {
        this.isDownloading = false;
      }
    },

    async downloadSpreadsheet() {
      // const headers = [
      //   'Date',
      //   'Time',
      //   'Location',
      //   'Home or away',
      //   'Opponent',
      //   'Shirt color',
      //   'Opponent shirt color'
      // ].join(',');
      // const rows = this.items.map(x => {
      //   const isHome = x.home.name == this.team;
      //   return [
      //     formatDate(x.dateRaw, { dateStyle: 'short' }),
      //     formatTime(x.timeRaw, { timeStyle: 'short' }),
      //     x.location.name,
      //     isHome ? 'Home' : 'Away',
      //     isHome ? x.away.name : x.home.name,
      //     (isHome ? x.home.color : x.away.color) || '',
      //     (isHome ? x.away.color : x.home.color) || ''
      //   ].map(quoteCSVTerm).join(',');
      // });
      // const data = 'data:text/csv;base64,' + btoa([ headers, ...rows ].join('\n'));

      const rows_csv = [
        [
          'Date',
          'Time',
          'Location',
          'Home or away',
          'Opponent',
          'Shirt color',
          'Opponent shirt color',
          'Notes'
        ]
      ];
      const rows_tc = await this.loadSchedule();
      for (const row of rows_tc.slice(1))
        rows_csv.push([
          row[1] && formatDate(row[1], { dateStyle: 'short' }),
          row[2] && formatTime(row[2], { timeStyle: 'short' }),
          row[8],
          row[6] || 'Bye',
          row[7],
          row[9],
          row[10],
          row[13]
        ]);
      const data = 'data:text/plain;base64,' + btoa(unescape(encodeURIComponent(rows_csv.map(x => x.map(quoteCSVTerm).join(',')).join('\n'))));
      download(data, `${this.team} ${this.season}.csv`);
    },

    async downloadTeamCowboy() {
      // const headers = [
      //   'Event Type',
      //   'Start Date',
      //   'Start Time',
      //   'End Date',
      //   'End Time',
      //   'Timezone ID',
      //   'Home or Away',
      //   'Opponent/Event Title',
      //   'Location Name',
      //   'Shirt Color',
      //   'Opponent Shirt Color',
      //   'Allow RSVPs',
      //   'Send Reminders',
      //   'Notes/Comments'
      // ].join('\t');
      // const rows = this.items.map(x => {
      //   const isHome = x.home.name == this.team;
      //   return [
      //     'game',
      //     formatDate(x.dateRaw, { dateStyle: 'full' }),
      //     formatTime(x.timeRaw, { timeStyle: 'medium' }),
      //     '',
      //     '',
      //     'US/Pacific',
      //     isHome ? 'Home' : 'Away',
      //     isHome ? x.away.name : x.home.name,
      //     x.location.name,
      //     isHome ? x.home.color : x.away.color,
      //     isHome ? x.away.color : x.home.color,
      //     'Yes',
      //     'Yes',
      //     ''
      //   ].map(quoteTabDelimitedTerm).join('\t');
      // });
      // const data = 'data:text/plain;charset=utf-8,' + [ headers, ...rows ].join('\n');
      const rows = await this.loadSchedule();
      for (const row of rows.slice(1)) {
        row[1] = row[1] && formatDate(row[1], { dateStyle: 'full' });
        row[2] = row[2] && formatTime(row[2], { timeStyle: 'medium' });
        row[3] = row[3] && formatDate(row[3], { dateStyle: 'full' });
        row[4] = row[4] && formatTime(row[4], { timeStyle: 'medium' });
      }
      const data = 'data:text/plain;base64,' + btoa(rows.map(x => x.map(quoteTabDelimitedTerm).join('\t')).join('\n'));
      download(data, `${this.team} ${this.season}.txt`);
    }
  },

  watch: {
    season() {
      this.load();
    },

    divisions() {
      this.division = null;
    },

    division() {
      this.team = '-';
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
